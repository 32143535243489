body
  color: $color--black
  font-family: "text", sans-serif
  font-weight: 400

.container
  max-width: $breakpoint--m
  margin:
    left: auto
    right: auto
  padding:
    left: var(--gap--m)
    right: var(--gap--m)

.scroll-to
  position: relative

  &__element
    position: absolute
    bottom: 100%
    display: block
    height: $gap--xxl
    background-color: red

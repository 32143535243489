ul,
ol,
h1,
h2,
h3,
p,
figure
  margin: 0

ul,
ol
  list-style: none
  padding: 0

summary
  list-style: none

  &::-webkit-details-marker,
  &::marker
    display: none

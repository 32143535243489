.text--title--l
  font-size: 4rem
  font-weight: 700
  line-height: 1.1

.text--title--m
  font-size: 2rem
  font-weight: 500
  line-height: 1.25

.text--title--s
  font-size: 1.25rem
  font-weight: 500

.label
  display: block
  width: fit-content
  padding: $gap--xs $gap--s
  font-size: 1rem
  font-weight: 500

  &.white
    background-color: $color--white
    color: $color--black

  &.black
    background-color: $color--black
    color: $color--white

@media screen and (width >= 901px) {
  :root {
    --gap--m: 3rem;
  }
}

@media screen and (width <= 900px) {
  :root {
    --gap--m: 1.5rem;
  }
}

@keyframes animation-contact {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes animation-rotation-loading {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

body {
  text-rendering: optimizeLegibility;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  width: 100%;
  height: auto;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

a {
  color: unset;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;
}

td, th {
  border: 1px solid #000;
  padding: .4rem;
}

input {
  border-radius: 0;
}

button::-moz-focus-inner {
  border: 0;
}

input[type="button"]::-moz-focus-inner {
  border: 0;
}

input[type="submit"]::-moz-focus-inner {
  border: 0;
}

input[type="reset"]::-moz-focus-inner {
  border: 0;
}

input[type="color"]::-moz-focus-inner {
  border: 0;
}

a:focus, textarea:focus, button:focus, input:focus {
  outline: none;
}

button {
  touch-action: manipulation;
}

input[type="button"], input[type="checkbox"], input[type="email"], input[type="file"], input[type="number"], input[type="reset"], input[type="search"], input[type="submit"], input[type="tel"], input[type="text"], input[type="url"], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input:invalid {
  box-shadow: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  resize: none;
}

@font-face {
  font-family: pyvlc-icons;
  src: url("pyvlc-icons.0cc645a6.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  speak: never;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  margin-left: .2em;
  margin-right: .2em;
  font-family: pyvlc-icons;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  display: inline-block;
}

.icon-mail:before {
  content: "";
}

.icon-right-arrow:before {
  content: "";
}

.icon-twitter-1:before {
  content: "";
}

.icon-telegram:before {
  content: "";
}

.icon-slack:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

ul, ol, h1, h2, h3, p, figure {
  margin: 0;
}

ul, ol {
  padding: 0;
  list-style: none;
}

summary {
  list-style: none;
}

summary::-webkit-details-marker {
  display: none;
}

summary::marker {
  display: none;
}

body {
  color: #252525;
  font-family: text, sans-serif;
  font-weight: 400;
}

.container {
  max-width: 1280px;
  padding-left: var(--gap--m);
  padding-right: var(--gap--m);
  margin-left: auto;
  margin-right: auto;
}

.scroll-to {
  position: relative;
}

.scroll-to__element {
  background-color: red;
  height: 7.5rem;
  display: block;
  position: absolute;
  bottom: 100%;
}

@font-face {
  font-family: text;
  src: url("space-grotesk.a03d586d.woff2") format("woff2-variations");
  font-weight: 300 700;
  font-style: normal;
  font-display: swap;
}

.text--body--l {
  font-size: 1.3rem;
  font-weight: 500;
}

.text--body--m {
  font-size: 1rem;
  font-weight: 300;
}

.text--body--s {
  font-size: .75rem;
  font-weight: 300;
}

.text--light {
  font-weight: 300;
}

.text--medium {
  font-weight: 500;
}

.text--align-center {
  text-align: center;
}

.text--orange {
  color: #fd7400;
}

.text--link--main, .text--link--black {
  text-decoration: underline;
  transition: all .3s ease-in-out;
}

.text--link--main:hover {
  color: #fd7400;
}

.text--link--black:hover {
  color: #252525;
  text-decoration-color: #0000;
}

.text--link--highlight {
  align-items: self-end;
  transition: all .3s ease-in-out;
  display: inline-flex;
  position: relative;
}

.text--link--highlight:before {
  content: "";
  z-index: 0;
  background-color: #fff;
  height: 1px;
  transition: all .3s ease-in-out;
  display: block;
  position: absolute;
  left: 0;
}

.text--link--highlight:not(.active):before {
  width: 0;
}

.text--link--highlight:hover:before, .text--link--highlight.active:before {
  width: 100%;
}

.label {
  width: fit-content;
  padding: .75rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  display: block;
}

.label.white {
  color: #252525;
  background-color: #fff;
}

.label.black {
  color: #fff;
  background-color: #252525;
}

.icon-open {
  transform-origin: center;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
}

.icon-open:before, .icon-open:after {
  content: "";
  background-color: #252525;
  border-radius: 1px;
  transition: all .3s ease-in-out;
  display: block;
  position: absolute;
}

.icon-open:before {
  width: 100%;
  height: 2px;
}

.icon-open:after {
  width: 2px;
  height: 100%;
}

.text--title--l {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.1;
}

.text--title--m {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.25;
}

.text--title--s {
  font-size: 1.25rem;
  font-weight: 500;
}

.header {
  backdrop-filter: blur(10px);
  z-index: 90;
  padding-top: 2rem;
  padding-bottom: 1rem;
  transition: all .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header__contact {
  color: #fd7400;
  background-color: #fd740026;
  border: 1px solid #fd7400;
  width: max-content;
  padding: .25rem 1rem;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
}

.header__contact:hover {
  color: #fff;
  background-color: #fd740080;
}

.header__main {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.header__main--button {
  background-color: unset;
  border: unset;
  z-index: 10;
  height: 2rem;
  padding: .25rem 0;
  display: flex;
  position: relative;
}

.header__main--button > span {
  background-color: #fff;
  height: 2px;
  transition: all .5s ease-in-out;
  display: block;
  position: absolute;
}

.header__main--button > span:not(.header__main--button > span:last-child) {
  align-self: center;
  width: 2rem;
}

.header__main--button > span:first-child {
  transform: translateY(-.75rem);
}

.header__main--button > span:last-child {
  opacity: 1;
  align-self: self-end;
  width: 1rem;
}

.header__main--button.open > span {
  background-color: #252525;
}

.header__main--button.open > span:first-child {
  transform: translateY(0)rotate(45deg);
}

.header__main--button.open > span:nth-child(2) {
  transform: rotate(-45deg);
}

.header__main--button.open > span:last-child {
  opacity: 0;
  width: 0;
}

.header__main .header__contact {
  margin-left: auto;
}

.header__nav {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: calc(2rem + 2rem * 2 + 1rem) var(--gap--m) 3.5rem;
  backdrop-filter: blur(10px);
  background-color: #ffffffe6;
  transition: all .5s ease-in-out;
  position: fixed;
  inset: 0;
  transform: translateX(-100%);
}

.header__nav.open {
  transform: translateX(0);
}

.header__nav--logo {
  box-sizing: border-box;
  width: 50%;
  height: 2rem;
  padding-right: var(--gap--m);
  display: flex;
  position: absolute;
  top: 2rem;
  right: 0;
}

.header__nav--logo > img {
  object-fit: contain;
  object-position: right;
}

.header__nav--list {
  row-gap: 1rem;
  font-size: 1.25rem;
  display: grid;
}

.header__nav--list > li:last-child {
  margin-top: .5rem;
}

.header__nav--list .text--link--highlight:before {
  background-color: #252525;
}

.header.main {
  background-color: #ffffff80;
}

.header.main .header__main--button > span {
  background-color: #252525;
}

.header.hidden {
  transform: translateY(-101%);
}

.hero {
  box-sizing: border-box;
  color: #fff;
  height: 100vh;
  min-height: fit-content;
  max-height: 70rem;
  padding-top: 5.75rem;
  padding-bottom: 5.75rem;
}

.footer {
  color: #67a7e0;
  background-color: #212121;
  padding-top: 5.75rem;
  padding-bottom: 3.5rem;
}

.footer__logo {
  row-gap: .75rem;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  display: grid;
  position: relative;
}

.footer__logo:after {
  content: "";
  background-color: #67a7e0;
  justify-self: center;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer__logo > img {
  width: 4rem;
}

.footer > section > p:last-child {
  margin-top: .25rem;
}

.grid-layout--with-photo {
  display: grid;
}

.grid-layout--with-photo__info {
  height: fit-content;
  padding-top: var(--gap--m);
  padding-bottom: var(--gap--m);
  align-self: center;
  gap: 1rem;
  display: grid;
}

.grid-layout--with-photo__photo {
  display: flex;
}

.grid-layout--with-photo__photo > img {
  aspect-ratio: 2;
  object-fit: cover;
}

@media screen and (width <= 500px) {
  .grid-layout--with-photo__photo > img {
    aspect-ratio: 4 / 3;
  }
}

.home--hero {
  background-image: url("hero-mobile.7f482996.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: left;
  align-items: flex-end;
  display: grid;
}

.home--hero__heading {
  row-gap: .75rem;
  display: grid;
}

.home--hero__heading--logo {
  width: 10rem;
  margin-bottom: .75rem;
}

.home--hero__heading--subtitle {
  max-width: 31rem;
}

.events {
  margin-top: 5.75rem;
}

.events__all {
  outline: 2px solid #0000;
  transition: all .3s ease-in-out;
}

.events__all--photo {
  position: relative;
  overflow: hidden;
}

.events__all--photo > .events__img {
  transform-origin: 100% 100%;
  object-position: right;
  transform: scale(1.5);
}

.events__all--photo > .events__img:hover {
  transform: scale(1.5);
}

.events__all--photo > .label {
  position: absolute;
  top: 0;
  right: 0;
}

.events__all--info {
  padding-left: var(--gap--m);
  padding-right: var(--gap--m);
  background-color: #fd7400;
  grid-template-columns: auto 1fr;
  grid-template-areas: "title title"
                       "subtitle subtitle"
                       "description description"
                       "date time"
                       "location location";
  gap: 1rem;
}

.events__all--info__title {
  grid-area: title;
  text-decoration-line: underline;
  text-decoration-color: #fd7400;
  transition: text-decoration-color .3s ease-in-out;
}

.events__all--info__subtitle {
  grid-area: subtitle;
}

.events__all--info__description {
  grid-area: description;
}

.events__all--info__date {
  grid-area: date;
}

.events__all--info__time {
  grid-area: time;
}

.events__all--info__location {
  grid-area: location;
}

.events__all:hover {
  outline-color: #252525;
}

.events__all:hover .events__all--info__title {
  text-decoration-color: #252525;
}

.no-event {
  background-color: #fd7400;
  margin-bottom: 7.5rem;
  margin-left: 0;
  margin-right: 0;
  padding: 2rem 2rem 7.5rem;
  position: relative;
}

.no-event:after {
  content: "";
  background-image: url("loading.5b9072c2.svg");
  background-size: cover;
  width: 120px;
  height: 120px;
  animation-name: animation-rotation-loading;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: block;
  position: absolute;
  bottom: -49px;
  right: 69px;
}

.no-event__title {
  margin-bottom: 2rem;
}

.no-event__wrap-link {
  margin: 2rem 0;
}

.no-event__wrap-image {
  background-color: #fff;
}

.no-event__image {
  object-fit: cover;
  object-position: center bottom;
  width: 100%;
  transform: translateX(1.5rem);
}

.community, .python {
  margin-top: 5.75rem;
}

.python__definition {
  row-gap: 1rem;
  display: grid;
}

.pros {
  background-color: #f6f4f1;
  margin-top: 5.75rem;
  padding-top: 5.75rem;
  padding-bottom: 5.75rem;
}

.pros__list {
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  display: grid;
}

@media screen and (width <= 500px) {
  .pros__list {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
}

.pros__list > li {
  grid-template-rows: auto 1fr;
  row-gap: 1rem;
  display: grid;
}

.pros__photo {
  display: none;
}

.faqs {
  margin-top: 5.75rem;
}

.faqs__all {
  row-gap: 2rem;
}

.faqs__all--questions {
  border: 1px solid #252525;
}

.faqs__all--questions__item {
  transition: all .3s ease-in-out;
}

.faqs__all--questions__item:not(.faqs__all--questions__item:last-child) {
  border-bottom: 1px solid #252525;
}

.faqs__all--questions__item > summary {
  padding: var(--gap--m);
  grid-template-columns: 1fr auto;
  column-gap: .75rem;
  transition: all .3s ease-in-out;
  display: grid;
}

.faqs__all--questions__item > summary:hover {
  color: #fd7400;
}

.faqs__all--questions__item > summary:hover > .icon-open:before, .faqs__all--questions__item > summary:hover > .icon-open:after {
  background-color: #fd7400;
}

.faqs__all--questions__item[open] {
  background-color: #f6f4f1;
}

.faqs__all--questions__item[open] > summary > .icon-open {
  transform: rotate(45deg);
}

.faqs__all--questions__item > p {
  padding-bottom: var(--gap--m);
  padding-left: var(--gap--m);
  padding-right: var(--gap--m);
}

.partners {
  row-gap: 3.5rem;
  margin-top: 7.5rem;
  display: grid;
}

.partners__list {
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
  gap: 1rem 2rem;
  display: flex;
}

.partners__link {
  opacity: .8;
  align-items: center;
  width: 100%;
  min-width: 9rem;
  max-width: 15rem;
  height: 5rem;
  transition: all .3s ease-in-out;
  display: flex;
}

.partners__link:hover {
  opacity: 1;
}

.partners__link:hover > .partners__image {
  transform: scale(1.1);
}

.partners__image {
  width: inherit;
  object-fit: contain;
  transition: all .3s ease-in-out;
}

.partners__image--idecrea {
  justify-self: right;
  height: 100%;
}

.partners__image--wayco {
  justify-self: left;
  height: 75%;
}

.code-of-conduct {
  background-color: #f6f4f1;
  margin-top: 7.5rem;
  padding-block: 7.5rem;
}

.code-of-conduct div > h2 {
  margin-bottom: 1rem;
}

.code-of-conduct div > h3 {
  margin-top: 2rem;
}

.code-of-conduct div > p {
  margin-top: .5rem;
}

.code-of-conduct div > ul {
  margin-block: 1rem;
}

.code-of-conduct div > ul > li {
  margin-top: .5rem;
  padding-left: 2rem;
  position: relative;
}

.code-of-conduct div > ul > li:before {
  content: "";
  font-family: pyvlc-icons;
  font-size: .7rem;
  position: absolute;
  top: .2rem;
  left: 0;
}

.code-of-conduct__more-content {
  transition: all .5s ease-in-out;
}

.code-of-conduct__more-button {
  background-color: unset;
  border: unset;
  padding: unset;
  margin-top: 2rem;
  transition: all .3s ease-in-out;
}

.code-of-conduct__more-button:before {
  background-color: #252525;
  height: 2px;
}

.code-of-conduct__more-button:hover {
  color: #fd7400;
}

.code-of-conduct__more-button:hover:before {
  background-color: #fd7400;
}

.newsletter {
  margin-top: 5.75rem;
}

.newsletter__content {
  padding: 2rem var(--gap--m);
  color: #fff;
  background-color: #252525;
  row-gap: .75rem;
  display: grid;
}

.newsletter__content > h2 {
  text-decoration-line: underline;
  text-decoration-color: #252525;
  transition: text-decoration-color .3s ease-in-out;
}

.newsletter__content--button {
  grid-auto-flow: column;
  align-items: center;
  column-gap: .5rem;
  margin-top: 2rem;
  padding-right: 2rem;
  transition: background-color .3s ease-in-out;
  display: grid;
}

.newsletter__content--button > .icon-right-arrow {
  transform-origin: center;
  font-size: .7rem;
  transition: all .3s ease-in-out;
}

.newsletter__content:hover > h2 {
  text-decoration-color: #fff;
}

.newsletter__content:hover > .newsletter__content--button {
  background-color: #fd7400;
}

.newsletter__content:hover > .newsletter__content--button > .icon-right-arrow {
  transform: translate(.5rem)scale(1.1)rotate(-9deg);
}

.contact {
  background-color: #67a7e0;
  margin-top: 5.75rem;
  display: grid;
}

.contact__title {
  border-bottom: 1px solid #252525;
  width: 100%;
  display: flex;
  overflow: hidden;
}

@media screen and (width <= 675px) {
  .contact__title {
    border-bottom: unset;
  }
}

.contact__title > h2 {
  flex-shrink: 0;
  font-weight: 400;
}

.contact__social-media {
  box-sizing: border-box;
  grid-template-columns: repeat(3, 1fr);
  justify-self: center;
  width: 100%;
  max-width: 1280px;
  display: grid;
}

@media screen and (width <= 675px) {
  .contact__social-media {
    border-left: unset;
    grid-template-columns: 1fr;
  }
}

.contact__social-media--item:not(.contact__social-media--item:last-child) {
  border-right: 1px solid #252525;
}

@media screen and (width <= 675px) {
  .contact__social-media--item:not(.contact__social-media--item:last-child) {
    border-right: unset;
  }

  .contact__social-media--item {
    border-top: 1px solid #252525;
  }
}

.contact__social-media--item__link {
  box-sizing: border-box;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  column-gap: .75rem;
  height: 100%;
  display: grid;
}

.contact__social-media--item__link > i, .contact__social-media--item__link > span {
  color: #252525;
  transition: all .3s ease-in-out;
}

.contact__social-media--item__link:hover > i, .contact__social-media--item__link:hover > span {
  color: #fff;
}

.contact__social-media--item__link > i:not(.icon-right-arrow) {
  justify-self: right;
}

.contact__social-media--item__link > .icon-telegram {
  font-size: 1.5rem;
}

.contact__social-media--item__link > .icon-twitter {
  font-size: 2rem;
}

.contact__social-media--item__link > .icon-slack {
  font-size: 1.75rem;
}

.contact__social-media--item__link > .icon-right-arrow {
  transform-origin: center;
  width: max-content;
  margin-top: .15rem;
  font-size: .75rem;
}

.contact__social-media--item__link:hover > .icon-right-arrow {
  transform: translate(.75rem)scale(1.25)rotate(-15deg);
}

.contact__email {
  border-top: 1px solid #252525;
  width: 100%;
  font-size: 1.75rem;
}

@media screen and (width <= 500px) {
  .contact__email {
    font-size: 1.25rem;
  }
}

.contact__email > i, .contact__email > i:before {
  justify-content: center;
  display: flex;
}

.contact__title, .contact__email, .contact__social-media--item__link {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.desktop-view {
  display: none;
}

.body-scroll-lock {
  overflow: hidden;
}
/*# sourceMappingURL=index.69cf9a91.css.map */

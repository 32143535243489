/*
  Typographic tweaks!
  Add accessible line-height
  Improve text rendering
*/
body {
  /* Common: Standardizes typography */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /* Common: Sets in common the way of rendering the text */
  text-rendering: optimizeLegibility;
  /* Common: Optimize load font. */
  font-display: swap;
  /* Common: Smooth the font on the level of the pixel */
  -webkit-font-smoothing: antialiased;
  /* Common: correct the line height */
  line-height: 1.5;
  margin: 0;
}

/*
  Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  width: 100%;
  height: auto;
}

/*
  Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}

/*
  Avoid text overflows and add hyphenation when needed
*/
p, h1, h2, h3, h4, h5, h6 {
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* -webkit-hyphens: auto; */
  /* -moz-hyphens: auto; */
  /* hyphens: auto; */
}

a {
  /* Remove the bottom line */
  text-decoration: none;
  /* Remove default color */
  color: unset;
  /* iOS: remove the color of the highlight that appears over a link while it's being tapped */
  -webkit-tap-highlight-color: transparent;
}

/*
  Table: Standardize and modernize the appearance
*/
table {
  border-collapse: collapse;
  margin-bottom: 10px;
  width: 100%;
}
td, th {
  padding: .4rem;
  border: 1px solid black;
}

input {
  /* Remove border radius */
  border-radius: 0;
}

/* Firefox: remove inner focus ring */
button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="color"]::-moz-focus-inner {
  border: 0;
}

a:focus,
textarea:focus,
button:focus,
input:focus {
  outline: none;
}

/* iOS: remove zoom double tap */
button {
  touch-action: manipulation;
}

/* iOS && Firefox: remove default input appearince */
/* Remove or add any input type */
input[type="button"],
input[type="checkbox"],
input[type="email"],
input[type="file"],
input[type="number"],
input[type="reset"],
input[type="search"],
input[type="submit"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* Firefox: remove default input:invalid appearence */
input:invalid {
  box-shadow: none;
}

/* iOS: remove the "cancel button" at the edge of an <input> of type="search" */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/* Remove resize option for textarea */
textarea {
  resize: none;
}

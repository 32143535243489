@keyframes animation-contact
  from
    transform: translateX(0)
  to
    transform: translateX(-100%)

@keyframes animation-rotation-loading
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

.footer
  color: $color--blue
  background-color: $color--black-footer
  padding:
    top: $gap--xl
    bottom: $gap--l

  &__logo
    position: relative
    display: grid
    row-gap: $gap--xs
    margin-bottom: $gap--m
    padding-bottom: $gap--m

    &::after
      content: ""
      position: absolute
      bottom: 0
      left: 0
      right: 0
      justify-self: center
      display: block
      height: 1px
      background-color: $color--blue

    & > img
      width: 4rem

  & > section > p:last-child
    margin-top: $gap--xxxs

.header
  $button-size: 2rem
  position: fixed
  right: 0
  left: 0
  top: 0
  padding:
    top: $gap--m
    bottom: $gap--s
  backdrop-filter: blur(10px)
  z-index: 90
  transition: $transition--main

  &__contact
    position: relative
    display: flex
    width: max-content
    padding: $gap--xxxs $gap--s
    color: $color--orange
    border: $border--main $color--orange
    background-color: rgba($color--orange, .15)
    transition: $transition--main

    &:hover
      color: $color--white
      background-color: rgba($color--orange, .5)

  &__main
    display: grid
    grid-template-columns: repeat(2, 1fr)

    &--button
      position: relative
      display: flex
      height: $button-size
      background-color: unset
      border: unset
      padding: $gap--xxxs 0
      z-index: 10

      & > span
        position: absolute
        display: block
        height: 2px
        background-color: $color--white
        transition: $transition--slow

        &:not(&:last-child)
          width: $button-size
          align-self: center

        &:first-child
          transform: translateY(calc(-#{$button-size} / 2 + #{$gap--xxxs}))

        &:last-child
          align-self: self-end
          width: calc(#{$button-size} / 2)
          opacity: 1

      &.open > span
        background-color: $color--black

        &:first-child
          transform: translateY(0) rotate(45deg)

        &:nth-child(2)
          transform: rotate(-45deg)

        &:last-child
          width: 0
          opacity: 0

    .header__contact
      margin-left: auto

  &__nav
    box-sizing: border-box
    position: fixed
    inset: 0
    height: 100vh
    width: 100%
    padding: calc(#{$button-size} + #{$gap--m} * 2 + #{$gap--s}) var(--gap--m) $gap--l
    background-color: rgba($color--white, .9)
    backdrop-filter: blur(10px)
    transform: translateX(-100%)
    transition: $transition--slow

    &.open
      transform: translateX(0)

    &--logo
      box-sizing: border-box
      position: absolute
      right: 0
      top: $gap--m
      display: flex
      height: $button-size
      width: 50%
      padding-right: var(--gap--m)

      & > img
        object:
          fit: contain
          position: right

    &--list
      display: grid
      row-gap: $gap--s
      font-size: 1.25rem

      & > li:last-child
        margin-top: $gap--xxs

      .text--link--highlight::before
        background-color: $color--black

  &.main
    background-color: rgba($color--white, .5)

    .header__main--button > span
      background-color: $color--black

  &.hidden
    transform: translateY(-101%)

.hero
  box-sizing: border-box
  height: 100vh
  max-height: 70rem
  min-height: fit-content
  color: $color--white
  padding:
    top: $gap--xl
    bottom: $gap--xl

.text--body--l
  font-size: 1.3rem
  font-weight: 500

.text--body--m
  font-size: 1rem
  font-weight: 300

.text--body--s
  font-size: .75rem
  font-weight: 300

.text--light
  font-weight: 300

.text--medium
  font-weight: 500

.text--align-center
  text-align: center

.text--orange
  color: $color--orange

.text--link

  &--main,
  &--black
    text-decoration: underline
    transition: $transition--main

  &--main:hover
    color: $color--orange

  &--black:hover
    color: $color--black
    text-decoration-color: transparent

.text--link--highlight
  position: relative
  display: inline-flex
  align-items: self-end
  transition: $transition--main

  &::before
    content: ""
    position: absolute
    left: 0
    display: block
    height: 1px
    background-color: $color--white
    z-index: 0
    transition: $transition--main

  &:not(.active)::before
    width: 0

  &:hover::before
    width: 100%

  &.active::before
    width: 100%

@font-face {
  font-family: 'pyvlc-icons';
  src: url('../fonts/pyvlc-icons.woff2?9804011') format('woff2');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "pyvlc-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  margin-left: .2em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before { content: '\e802'; } 
.icon-right-arrow:before { content: '\e803'; }
.icon-twitter-1:before { content: '\e804'; }
.icon-telegram:before { content: '\e805'; }
.icon-slack:before { content: '\e806'; }
.icon-twitter:before { content: '\f099'; }
